import {NuxtIcon} from "#components";
import {h} from "vue";
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 20 18"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "m19.898 5.89-1.05-4.37c-.22-.9-1-1.52-1.91-1.52H3.048c-.9 0-1.69.63-1.9 1.52L.098 5.89c-.24 1.02-.02 2.06.62 2.88.08.11.19.19.28.29V16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V9.06c.09-.09.2-.18.28-.28.64-.82.87-1.87.62-2.89m-2.99-3.9 1.05 4.37c.1.42.01.84-.25 1.17-.14.18-.44.47-.94.47-.61 0-1.14-.49-1.21-1.14L14.978 2zm-5.91.01h1.96l.54 4.52c.05.39-.07.78-.33 1.07-.22.26-.54.41-.95.41-.67 0-1.22-.59-1.22-1.31zm-4.51 4.52L7.038 2h1.96v4.69c0 .72-.55 1.31-1.29 1.31-.34 0-.65-.15-.89-.41a1.42 1.42 0 0 1-.33-1.07m-4.45-.16L3.048 2h1.97l-.58 4.86c-.08.65-.6 1.14-1.21 1.14-.49 0-.8-.29-.93-.47-.27-.32-.36-.75-.26-1.17m.96 9.64V9.97c.08.01.15.03.23.03.87 0 1.66-.36 2.24-.95.6.6 1.4.95 2.31.95.87 0 1.65-.36 2.23-.93.59.57 1.39.93 2.29.93.84 0 1.64-.35 2.24-.95.58.59 1.37.95 2.24.95.08 0 .15-.02.23-.03V16z"
    }, null, -1)
  ])))
}
export default { render() { return h(NuxtIcon, {icon: {render}, name: "store"}) } }