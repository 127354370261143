import {NuxtIcon} from "#components";
import {h} from "vue";
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 21 14"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M20.5.75a.75.75 0 0 1-.75.75H1.25a.75.75 0 0 1 0-1.5h18.5a.75.75 0 0 1 .75.75M20.5 6.746a.75.75 0 0 1-.75.75H1.25a.75.75 0 0 1 0-1.5h18.5a.75.75 0 0 1 .75.75M20.5 12.743a.75.75 0 0 1-.75.75H1.25a.75.75 0 0 1 0-1.5h18.5a.75.75 0 0 1 .75.75",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render() { return h(NuxtIcon, {icon: {render}, name: "mobile-menu"}) } }