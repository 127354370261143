<script setup lang="ts">
const { $cookiePro } = useNuxtApp();

const onClickCookiePreferences = () => {
  $cookiePro.showCookiePreferences();
};
</script>

<template>
  <div class="my-2 flex flex-col items-center gap-2">
    <div class="flex justify-center gap-4">
      <NuxtLink
        to="https://www.tastenetwork.io/terms-of-service"
        target="_blank"
        class="text-xs text-blue-400 hover:text-blue-200"
      >
        Terms of Service
      </NuxtLink>
      <NuxtLink
        to="https://www.tastenetwork.io/privacy"
        target="_blank"
        class="text-xs text-blue-400 hover:text-blue-200"
      >
        Privacy Policy
      </NuxtLink>
    </div>
    <button
      class="text-xs text-blue-400 hover:text-blue-200"
      @click="onClickCookiePreferences"
    >
      Cookie Preferences
    </button>
  </div>
</template>
