import {NuxtIcon} from "#components";
import {h} from "vue";
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 18 18"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#4095DA",
      d: "m14 5-1.41 1.41L14.17 8H6v2h8.17l-1.58 1.58L14 13l4-4zM2 2h7V0H2C.9 0 0 .9 0 2v14c0 1.1.9 2 2 2h7v-2H2z"
    }, null, -1)
  ])))
}
export default { render() { return h(NuxtIcon, {icon: {render}, name: "logout"}) } }